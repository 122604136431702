<template>
    <div class="pa-4">
        <v-data-table
            :headers="headers"
            :items="items"
            sort-by="defect_no"
            :sort-desc="true"
            class="elevation-1"
            :search="search"
        >
            <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Defect Stock</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>                
                <v-spacer></v-spacer>
                <v-btn color="primary" dark @click="initialize">
                  Refresh
                </v-btn>
                <v-btn color="primary" dark class="ml-2" @click="addDefectStock" v-if="$store.state.currentPagePriv.create">
                  Add New
                </v-btn>
                <v-dialog v-model="dialog" v-if="dialog" max-width="800px">
                <v-card>
                    <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <span>Tanggal Penerimaan Barang Retur</span>
                                <v-menu v-model="datepicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="editedItem.return_date" filled rounded dense hide-details="auto" :rules="reqRule" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="editedItem.return_date" @input="datepicker = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <span>Total Penerimaan Barang Retur</span>
                                <v-text-field type="number" v-model="editedItem.return_qty" filled rounded dense hide-details="auto" :rules="reqRule" :suffix="editedItem.uom" @wheel="$event.target.blur()"></v-text-field>
                            </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                    </v-card-text>
                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                        Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="saveRetur">
                        Save
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>

                <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="body-1 text-center">Anda akan menghapus defect stock ini?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }" v-if="$store.state.currentPagePriv.delete">              
                  <v-icon small @click="deleteItem(item)" v-bind="attrs" v-on="on" v-show="!item.return_qty">
                      mdi-delete
                  </v-icon>
                </template>
                <span>Hapus Defect</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }" v-if="item.return">              
                  <v-icon small class="ml-2" @click="editItem(item)" v-bind="attrs" v-on="on">
                      mdi-call-received
                  </v-icon>
                </template>
                <span>Penerimaan Retur</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Reset
            </v-btn>
            </template>
            <template v-slot:[`item.material_name`]="{ item }">
              {{getRawMaterialName(item.material_code)}}
            </template>            
            <template v-slot:[`item.return`]="{ item }">
              {{(item.return!==true)?'Pencatatan Defect':'Retur Stock'}}
            </template>            
            <!-- <template v-slot:[`item.category_code`]="{ item }">
              {{getCategoryName(item.category_code)}}
            </template>
            <template v-slot:[`item.supplier_code`]="{ item }">
              {{getSupplierName(item.supplier_code)}}
            </template> -->
        </v-data-table>
        <defect ref="defectRef" @refresh="initialize"></defect>

    </div>
</template>

<script>
import Defect from '../components/Defect.vue'

  export default {
    components: { 
        Defect 
    },
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'No Doc', value: 'defect_no' },
        { text: 'Tanggal', value: 'doc_date' },
        { text: 'Reference', value: 'reference_code' },
        { text: 'Kode Bahan', value: 'material_code' },
        { text: 'Nama Bahan', value: 'material_name' },
        { text: 'Jumlah Defect', value: 'defect_qty' },
        { text: 'Tipe Pencatatan', value: 'return' },
        { text: 'Tgl Penerimaan', value: 'return_date' },
        { text: 'Jml Penerimaan', value: 'return_qty' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      editedIndex: -1,
      search: '',
      suppliers: [],
      categories: [],
      reqRule: [v => !!v || 'Harus diisi!'],
      valid: true,
      rawmaterials: [],
      datepicker: false,
      defaultItem: {
        return_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      },
      editedItem: {
        return_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      },

    }),

    computed: {
      formTitle () {
        return 'Penerimaan Barang Retur'
      },
      roles () {
        return this.$store.state.role.objs
      },
      uom () {
        return this.$store.state.rawmaterial.uom
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        this.rawmaterials = (await this.$store.dispatch('rawmaterial/getObjs')).data.data
        let response = await this.$store.dispatch('getObjs', {apidomain:'defectStocks', parameter:''})
        if (response.data.status=='success') this.items = response.data.data
      },
      addDefectStock() {
        this.$refs.defectRef.openDialogAdd()
      },

      getRawMaterialName(code) {
        return this.rawmaterials.find(element => {
          return element.code == code
        }).description;
      },
 
      editItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.editedItem.return_date = this.editedItem.return_date || (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        this.editedItem.return_qty = this.editedItem.return_qty || this.editedItem.defect_qty
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        let url = this.$store.state.config.apiHost + "defectStocks/" + this.editedItem.defect_no
        let response = await this.$axios.delete(url, {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
        console.log(response.data)
        if (response.data.status=='success') this.items.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async saveRetur () {
        await this.$refs.form.validate();
        if (!this.valid) return;

        let payload = {
          return_date: this.editedItem.return_date,
          return_qty: this.editedItem.return_qty
        }

        let url = this.$store.state.config.apiHost + "defectStocks/" + this.editedItem.defect_no + "/return"
        let response = await this.$axios.put(url, JSON.stringify(payload), {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
        console.log(response.data.data)
        if (response.data.status=='success') Object.assign(this.items[this.editedIndex], response.data.data)

        this.close()
      },
    },
  }
</script>

<style>
.v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot {
    border-radius: 7px!important;
}

</style>